import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import Dialog from './Dialog';

const ChangePassword = (props) => {
  const { fetcher, handleClose } = props;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const handlePasswordChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (newPassword1 === newPassword2) {
      fetcher(`${process.env.REACT_APP_API_URL}/auth/password`, {
        method: 'POST',
        body: JSON.stringify({
          currentPassword,
          newPassword: newPassword1
        })
      })
        .then(function (jsonData) {
          toast.success('Clave modificada exitosamente');
          handleClose();
        })
        .catch(function (error) {
          toast.error(error.message);
        });
    } else {
      toast.error("Las claves no coinciden");
    }
    setCurrentPassword("");
    setNewPassword1("");
    setNewPassword2("");
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      title="Cambio de clave"
      handleFormSubmit={handlePasswordChange}
      content={
        <React.Fragment>
          <TextField fullWidth type="password" margin="normal" label="Clave actual" required value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
          <TextField fullWidth type="password" margin="normal" label="Clave nueva" required value={newPassword1} onChange={(e) => setNewPassword1(e.target.value)} />
          <TextField fullWidth type="password" margin="normal" label="Repita clave nueva" required value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} />
        </React.Fragment>
      }
      actions={
        <Button type="submit" variant="contained" color="primary">Guardar</Button>
      }
    />
  )
}

export default ChangePassword;