import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, ListItemText, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { esES } from '@mui/x-data-grid/locales';
import { LineChart } from '@mui/x-charts/LineChart';
import { Timeline, ViewList } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
var isBetween = require("dayjs/plugin/isBetween");

function History(props) {
  const { fetcher, item, markets, fullScreen } = props;
  const [view, setView] = useState('list');
  const [productPrices, setProductPrices] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedMarkets, setSelectedMarkets] = useState(markets.map(m => m.name));

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/product/${item.id}/prices`, {}, false)
      .then(prices => {
        setProductPrices(prices.sort((a, b) => new Date(b.date) - new Date(a.date)));
      })
      .catch(_ => toast.error('Ha ocurrido un error al obtener los precios'));
  }, []);

  dayjs.extend(isBetween);

  const handleChange = (e, nextView) => {
    setView(nextView);
  };

  const columns = [
    { field: "date", headerName: "Fecha", type: 'dateTime', width: 150, valueGetter: (value) => new Date(value) },
    ...markets.filter(m => selectedMarkets.indexOf(m.name) > -1).map((market) => {
      return {
        field: market.name,
        headerName: market.name.charAt(0).toUpperCase() + market.name.slice(1),
        type: 'number',
        headerClassName: "columnHeader-" + market.name,
        headerAlign: 'left',
        width: 110,
        renderCell: (params) => params.row[market.id] && params.row[market.id] != 0 ? '$' + params.row[market.id] : '',
        valueGetter: (value, row) => row[market.id] && row[market.id] != 0 ? row[market.id] : ''
      }
    })
  ];

  const productPricesFiltered = productPrices.filter(itm => dayjs(itm.date).isBetween(startDate, endDate, 'day', '[]'));

  const castProductPrice = (item) => {
    let newItem = {}
    Object.keys(item).forEach(k => newItem[k] = (k == 'date' ? new Date(item[k]) : parseFloat(item[k])))
    markets.forEach(market => newItem[market.id] = newItem[market.id] || null);
    return newItem;
  }

  const headerClasses = {
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: 'inherit'
    }
  }

  markets.forEach(m => {
    let keyName = `& .columnHeader-${m.name}`;
    headerClasses[keyName] = {
      backgroundColor: m.backgroundColor,
      color: m.color,
      fontWeight: 'bolder',
      fontSize: 'large',
      boxShadow: 'inset 0 0 40px rgba(0, 0, 0, 0.15)'
    }
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleMarketChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMarkets(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Box>
      <Box mx={{ display: 'inline', marginRight: 20 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} label="Fecha de inicio" maxDate={endDate} />
          <Box sx={{ display: 'inline', mx: 1 }}>-</Box>
          <DatePicker value={endDate} onChange={(newValue) => setEndDate(newValue)} label="Fecha de fin" minDate={startDate} maxDate={dayjs()} />
        </LocalizationProvider>
      </Box>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="markets">Supermercados</InputLabel>
        <Select
          labelId="markets"
          multiple
          value={selectedMarkets}
          onChange={handleMarketChange}
          input={<OutlinedInput label="Supermercados" />}
          renderValue={(selected) => `${selected.length} seleccionados`}
          MenuProps={MenuProps}
        >
          {markets.map((market) => (
            <MenuItem key={market.name} value={market.name}>
              <Checkbox checked={selectedMarkets.indexOf(market.name) > -1} />
              <ListItemText primary={market.name.charAt(0).toUpperCase() + market.name.slice(1)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleChange}
        sx={{ ml: 2, mb: 2 }}
      >
        <ToggleButton value="list" aria-label="list">
          <ViewList />
        </ToggleButton>
        <ToggleButton value="chart" aria-label="module">
          <Timeline />
        </ToggleButton>
      </ToggleButtonGroup>
      {productPrices.length === 0 ?
          [1, 2, 3, 4, 5, 6].map(i => <Skeleton key={i} variant="text" width="100%" height={30} />)
        : view === 'list' ?
          <Box sx={{ height: (fullScreen ? "70dvh" : "60dvh") }}>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={productPricesFiltered}
              columns={columns}
              disableColumnSelector
              disableRowSelectionOnClick
              getRowId={(row) => row.date}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10000, page: 0 },
                },
              }}
              pageSizeOptions={[10000]}
              sx={{
                ...headerClasses
              }}
            />
          </Box>
          :
          <Box sx={{ height: (fullScreen ? "70dvh" : "60dvh") }}>
            <LineChart
              xAxis={[
                {
                  dataKey: 'date',
                  scaleType: 'time'
                },
              ]}
              series={markets.filter(market => selectedMarkets.indexOf(market.name) > -1).map(market => ({
                dataKey: market.id.toString(),
                label: market.name.charAt(0).toUpperCase() + market.name.slice(1),
                color: market.backgroundColor
              }))}
              dataset={[...productPricesFiltered].sort((a, b) => new Date(a.date) - new Date(b.date)).map(e => castProductPrice(e))}
            />
          </Box>
      }
    </Box>
  );
}

export default History;