import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, FormGroup, FormControlLabel, TextField, Tooltip, IconButton } from '@mui/material';
import { Delete, Edit, ListAlt } from '@mui/icons-material';
import { DataGrid } from "@mui/x-data-grid";
import { esES } from '@mui/x-data-grid/locales';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import Dialog from './Dialog';
import UsersProducts from './UsersProducts';

function Users(props) {
  const { fetcher } = props;
  const [users, setUsers] = useState([]);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [productsModalShow, setProductsModalShow] = useState(false);
  const [itemModal, setItemModal] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getUsers()
  }, []);

  const getUsers = () => fetcher(`${process.env.REACT_APP_API_URL}/user`)
    .then(res => setUsers(res))
    .catch(_ => toast.error('Ha ocurrido un error al obtener los usuarios'));

  const handleEditModalShow = (item) => {
    if (item) {
      fetcher(`${process.env.REACT_APP_API_URL}/user/${item.id}`)
        .then(res => {
          setItemModal(res);
          setEditModalShow(true);
        })
    } else {
      setItemModal({
        username: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        password: '',
        isBlocked: 0
      });
      setEditModalShow(true);
    }
  }

  const handleProductsModalShow = (itm) => {
    setProductsModalShow(true);
    setItemModal(itm);
  }

  const handleProductsModalClose = () => {
    setProductsModalShow(false);
    setItemModal();
  }

  const handleEditModalClose = () => {
    setEditModalShow(false);
    setItemModal();
  }

  const handleDeleteModalShow = (item) => {
    setItemModal(item);
    setDeleteModalShow(true);
  }

  const handleDeleteModalClose = () => {
    setDeleteModalShow(false);
    setItemModal();
  }

  const handleEditModalSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    fetcher(`${process.env.REACT_APP_API_URL}/user/${itemModal.id || ''}`, {
      method: itemModal.id ? 'PUT' : 'POST',
      body: JSON.stringify(itemModal),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(_ => {
        handleEditModalClose();
        toast.success(itemModal.id ? 'Usuario modificado exitosamente' : 'Usuario agregado exitosamente');
      })
      .then(_ => getUsers())
      .catch(function (err) {
        toast.error(err.message || 'Ha ocurrido un error al guardar el usuario');
      });
  };

  const handleDelete = () => {
    fetcher(`${process.env.REACT_APP_API_URL}/user/${itemModal.id}`, {
      method: 'DELETE'
    })
      .then(_ => getUsers())
      .then(_ => {
        handleDeleteModalClose();
        toast.success('Usuario eliminado exitosamente');
      })
      .catch(function (err) {
        toast.error(err.message || 'Ha ocurrido un error al eliminar el usuario');
      });
  }

  const columns = [
    { field: "username", headerName: "Usuario", width: 150 },
    { field: "name", headerName: "Nombre", width: 150 },
    { field: "surname", headerName: "Apellido", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phone", headerName: "Teléfono", width: 150 },
    { field: "lastLogin", headerName: "Ult. acceso", width: 150, type: 'dateTime', valueGetter: (value) => new Date(value) },
    { field: "isBlocked", headerName: "Bloqueado", width: 80, type: 'boolean' },
    { field: "isAdmin", headerName: "Admin", width: 80, type: 'boolean' },
    {
      field: "action",
      type: "actions",
      resizable: false,
      width: 120,
      renderCell: (params) => {
        return <>
          <IconButton onClick={() => handleEditModalShow(params.row)}><Tooltip title="Editar"><Edit /></Tooltip></IconButton>
          <IconButton onClick={() => handleProductsModalShow(params.row)} disabled={params.row.isAdmin === 1}><Tooltip title="Productos"><ListAlt /></Tooltip></IconButton>
          <IconButton onClick={() => handleDeleteModalShow(params.row)}><Tooltip title="Eliminar"><Delete /></Tooltip></IconButton>
        </>
      }
    }
  ];

  const rows = users;

  return (
    <Box>
      <Box sx={{ textAlign: "right" }}>
        <Button variant="contained" size="small" onClick={() => handleEditModalShow()} sx={{ marginBottom: 2, marginLeft: 2 }}>Agregar usuario</Button>
      </Box>

      <Box sx={{ height: "calc(100dvh - 64px - 46px - 40px)" }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10000, page: 0 },
            },
          }}
          pageSizeOptions={[10000]}
        />
      </Box>


      {itemModal ?
        <>
          <Dialog
            open={editModalShow}
            onClose={handleEditModalClose}
            title="Edición de usuario"
            handleFormSubmit={handleEditModalSubmit}
            content={
              <>
                <TextField required fullWidth margin="dense" label="Usuario" type="text" value={itemModal.username} onChange={(e) => setItemModal({ ...itemModal, username: e.target.value })} />
                <TextField fullWidth margin="dense" label="Nombre" type="text" value={itemModal.name} onChange={(e) => setItemModal({ ...itemModal, name: e.target.value })} />
                <TextField fullWidth margin="dense" label="Apellido" type="text" value={itemModal.surname} onChange={(e) => setItemModal({ ...itemModal, surname: e.target.value })} />
                <TextField fullWidth margin="dense" label="Email" type="email" value={itemModal.email} onChange={(e) => setItemModal({ ...itemModal, email: e.target.value })} />
                <TextField fullWidth margin="dense" label="Teléfono" type="text" value={itemModal.phone} onChange={(e) => setItemModal({ ...itemModal, phone: e.target.value })} />
                <TextField required={!itemModal.id && itemModal.id !== 0} fullWidth margin="dense" label="Clave" type="password" value={itemModal.password} onChange={(e) => setItemModal({ ...itemModal, password: e.target.value })} />
                <FormGroup>
                  <FormControlLabel control={<Checkbox disabled={itemModal.isAdmin} checked={itemModal.isBlocked === 1} onChange={(e) => setItemModal({ ...itemModal, isBlocked: e.target.checked ? 1 : 0 })} />} label="Bloqueado" />
                </FormGroup>
              </>}
            actions={
              <Button variant="contained" color="primary" type="submit">Guardar</Button>
            }
          />
          <Dialog
            open={deleteModalShow}
            onClose={handleDeleteModalClose}
            title="¡Atención!" content={<>¿Está seguro que desea eliminar el usuario <b>{itemModal.username}</b>?<br />Se eliminarán todos los datos del mismo</>}
            actions={<>
              <Button variant="outlined" color="primary" onClick={handleDeleteModalClose}>Cancelar</Button>
              <Button variant="contained" color="primary" onClick={handleDelete}>Eliminar</Button>
            </>}
          />
          <Dialog
            open={productsModalShow}
            onClose={handleProductsModalClose}
            fullScreen={fullScreen}
            title={<React.Fragment>Productos - <b>{itemModal.username}</b></React.Fragment>}
            maxWidth='xl'
            content={
              <UsersProducts user={itemModal} fetcher={fetcher} fullScreen={fullScreen} handleClose={handleProductsModalClose} />
            }
          />
        </>
        : ''
      }
    </Box>
  );
}

export default Users;
