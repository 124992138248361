import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Skeleton, Typography } from '@mui/material';
import toast from 'react-hot-toast';

function Validation(props) {
  const { fetcher, markets, item } = props;
  const [productPrices, setProductPrices] = useState([]);

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/product/${item.id}/validate`, {}, false)
      .then(res => {
        setProductPrices(res);
      })
      .catch(_ => toast.error('Ha ocurrido un error al obtener los productos'));
  }, []);

  return (
    <Box>

      {markets.map(m => {
        let prod = productPrices.find(itm => itm.marketId === m.id) || {};
        return <Card key={m.id} sx={{ display: 'flex', marginBottom: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" sx={{ fontSize: '1em' }}>
                {m.name.charAt(0).toUpperCase() + m.name.slice(1)}
              </Typography>
              {productPrices.length > 0 ?
                <>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {prod.title}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {prod.price ? `$${prod.price}` : prod.error}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    {prod.link ? <a href={prod.link} target='_blank'><Button variant="outlined">Link</Button></a> : ''}
                  </Box>
                </>
                :
                <>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={300} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                </>
              }
            </CardContent>
          </Box>
          {productPrices.length > 0 ?
            prod.image ?
              <CardMedia
                component="img"
                sx={{ width: 151, height: 181 }}
                image={prod.image}
              /> : ''
            :
            <Skeleton variant="rectangular" width={151} height={102} />
          }
        </Card>
      })}
    </Box>
  );
}

export default Validation;