import React from "react";
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { ChevronLeft, ListAlt, Person, Schedule, Search, Settings } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function LeftMenu(props) {
  const { toggleDrawer, open, isAdmin } = props;
  const { pathname } = useLocation();

  return <Drawer variant="permanent" open={open}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
      }}
    >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeft />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav">
      <ListItemButton component={RouterLink} to="/" selected={pathname === "/"}>
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary="Productos" />
      </ListItemButton>
      {isAdmin ?
        <>
          <ListItemButton component={RouterLink} to="/users" selected={pathname === "/users"}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/searchproducts" selected={pathname === "/searchproducts"}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary="Buscar productos" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/searches" selected={pathname === "/searches"}>
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText primary="Actualizaciones" />
          </ListItemButton>
        </>
        : <React.Fragment></React.Fragment>
      }
      <ListItemButton component={RouterLink} to="/settings" selected={pathname === "/settings"}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Configuraciones" />
      </ListItemButton>
    </List>
  </Drawer>
}

export default LeftMenu;