import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid, ListItem, ListItemIcon } from '@mui/material';
import { Badge, Email, Person, Phone } from '@mui/icons-material';
import toast from 'react-hot-toast';

function Settings(props) {
  const { fetcher, user } = props;
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/market`)
      .then(res => setMarkets(res))
      .catch(_ => toast.error('Ha ocurrido un error al obtener los supermercados'));
  }, []);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={3}>
          <Card>
            <CardHeader title="Cuenta" />
            <CardContent>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, paddingRight: '10px' }}>
                  <Person />
                </ListItemIcon>
                {user.username}
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, paddingRight: '10px' }}>
                  <Badge />
                </ListItemIcon>
                {user.name} {user.surname}
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, paddingRight: '10px' }}>
                  <Email />
                </ListItemIcon>
                {user.email}
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, paddingRight: '10px' }}>
                  <Phone />
                </ListItemIcon>
                {user.phone}
              </ListItem>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Card>
            <CardHeader title="Supermercados" />
            <CardContent>
              <FormGroup>
                {markets.map(market => <FormControlLabel key={market.id} control={<Checkbox defaultChecked disabled />} label={market.name.charAt(0).toUpperCase() + market.name.slice(1)} />)}
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </Box>
  );
}

export default Settings;