import './App.css';
import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Toolbar } from '@mui/material';
import Products from './Products';
import Settings from './Settings';
import Users from './Users';
import SearchProducts from './SearchProducts';
import Searches from './Searches';
import Login from './Login';
import NavBar from './NavBar';
import LeftMenu from './LeftMenu';
import { Toaster } from 'react-hot-toast';

function App() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);

  const defaultTheme = createTheme();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      setUser(JSON.parse(token));
    }
  }, []);

  let fetcher = (url, opt, showLoading = true) => {
    let headers = opt ? opt.headers : {}
    opt = { ...opt, headers: { ...headers, 'Content-Type': 'application/json' } }
    if (user) opt.headers.Authorization = `Bearer ${user.token}`
    return new Promise((resolve, reject) => {
      if (showLoading) setLoading(true);
      fetch(url, opt)
        .then(async function (response) {
          if (response.ok) {
            return response.json();
          } else {
            const error = await response.json();
            throw error;
          }
        })
        .then(data => resolve(data))
        .catch(e => { e.code === 401 ? removeSession() : reject(e) })
        .finally(_ => { if (showLoading) setLoading(false) });
    });
  }

  let removeSession = () => {
    setUser();
    localStorage.removeItem('token');
  }

  let setSession = (user) => {
    setUser(user);
    localStorage.setItem('token', JSON.stringify(user));
  }

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }} open={loading}><CircularProgress color="inherit" /></Backdrop>
        <Toaster position="top-right" />
        {user ?
          <React.Fragment>
            <NavBar open={openDrawer} toggleDrawer={toggleDrawer} user={user} removeSession={removeSession} fetcher={fetcher} />
            <LeftMenu open={openDrawer} toggleDrawer={toggleDrawer} isAdmin={user.isAdmin} />
            <Box component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                height: '100dvh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}>
              <Toolbar />
              <Box sx={{ height: '100%', overflow: 'auto', padding: 2 }}>
                <Routes>
                  <Route path="/settings" element={<Settings fetcher={fetcher} user={user} />} />
                  <Route path="/users" element={<Users fetcher={fetcher} />} />
                  <Route path="/searches" element={<Searches fetcher={fetcher} />} />
                  <Route path="/searchproducts" element={<SearchProducts fetcher={fetcher} />} />
                  <Route path="/*" element={<Products fetcher={fetcher} user={user} />} />
                </Routes>
              </Box>
            </Box>
          </React.Fragment>
          :
          <Routes>
            <Route path="/*" element={<Login fetcher={fetcher} setSession={setSession} />} />
          </Routes>
        }
      </Box>
    </ThemeProvider>
  );
}

export default App;
