import React, { useState, useEffect } from "react";
import { Box } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { esES } from '@mui/x-data-grid/locales';
import toast from 'react-hot-toast';

function Searches(props) {
  const { fetcher } = props;
  const [searches, setSearches] = useState([]);

  useEffect(() => {
    getSearches()
  }, []);

  const getSearches = () => fetcher(`${process.env.REACT_APP_API_URL}/search`)
    .then(res => setSearches(res))
    .catch(_ => toast.error('Ha ocurrido un error al obtener las busquedas'));

  const columns = [
    { field: "idUser", headerName: "Iniciado por", width: 150, valueFormatter: (value, params) => value ? 'Usuario' : 'Cron' },
    { field: "startDate", headerName: "Inicio", width: 150, type: 'dateTime', valueGetter: (value) => new Date(value) },
    { field: "endDate", headerName: "Fin", width: 150, type: 'dateTime', valueGetter: (value) => new Date(value) },
    { field: "productsFound", headerName: "Busquedas corrrectas", width: 150 },
    { field: "productsTotal", headerName: "Busquedas total", width: 150 }
  ];

  const rows = searches;

  return (
    <Box sx={{ height: "calc(100dvh - 64px - 46px)" }}>
      <DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        disableColumnSelector
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10000, page: 0 },
          },
          sorting: {
            sortModel: [{ field: 'startDate', sort: 'desc' }],
          }
        }}
        pageSizeOptions={[10000]}
      />
    </Box>
  );
}

export default Searches;
