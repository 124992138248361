import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, InputLabel, TextField, Tooltip, IconButton, Typography } from '@mui/material';
import { Delete, Done, Edit, Search, Sync } from '@mui/icons-material';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid";
import { esES } from '@mui/x-data-grid/locales';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import Dialog from './Dialog';
import History from './History';
import Validation from './Validation';
import { ExportButton } from './excel/ExportButton';

function CustomToolbar(props) {
  const { showHistoryPrices, setShowHistoryPrices, ...newProps } = props;
  return (
    <GridToolbarContainer {...newProps}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <FormGroup>
        <FormControlLabel sx={{ marginRight: 0 }} control={
          <Checkbox color="primary" size="small" checked={showHistoryPrices}
            onChange={(e) => setShowHistoryPrices(e.target.checked)} />
        } label={<Typography sx={{ fontSize: '0.8125rem', color: 'primary.main' }}>VER PRECIOS DESACTUALIZADOS</Typography>} />
      </FormGroup>
      <ExportButton />
    </GridToolbarContainer>
  );
}

function Products(props) {
  const { fetcher, user } = props;
  const [products, setProducts] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [pricesModalShow, setPricesModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [validateModalShow, setValidateModalShow] = useState(false);
  const [showHistoryPrices, setShowHistoryPrices] = useState(true);
  const [itemModal, setItemModal] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/market`)
      .then(res => setMarkets(res))
      .catch(_ => toast.error('Ha ocurrido un error al obtener los supermercados'));
  }, []);

  useEffect(() => {
    if (markets.length > 0) {
      getProducts();
    }
  }, [markets]);

  const getProducts = (showLoading = true) =>
    fetcher(`${process.env.REACT_APP_API_URL}/product/prices`, {}, showLoading)
      .then(res => setProducts(res))
      .catch(_ => toast.error('Ha ocurrido un error al obtener los productos'));

  const handleEditModalShow = (item) => {
    if (item) {
      fetcher(`${process.env.REACT_APP_API_URL}/product/${item.id}`)
        .then(res => {
          setItemModal(res);
          setEditModalShow(true);
        })
    } else {
      setItemModal({
        product: '',
        brand: '',
        variety: '',
        size: '',
        sizeUnit: '',
        ean: '',
        codes: []
      });
      setEditModalShow(true);
    }
  }

  const handleValidateModalShow = (item) => {
    setItemModal(item);
    setValidateModalShow(true);
  }

  const handleEditModalClose = () => {
    setEditModalShow(false);
    setItemModal();
  }

  const handleDeleteModalShow = (item) => {
    setItemModal(item);
    setDeleteModalShow(true);
  }

  const handleDeleteModalClose = () => {
    setDeleteModalShow(false);
    setItemModal();
  }

  const handleValidateModalClose = () => {
    setValidateModalShow(false);
    if(!editModalShow)
      setItemModal();
  }

  const handleEditModalSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    fetcher(`${process.env.REACT_APP_API_URL}/product/${itemModal.id || ''}`, {
      method: itemModal.id ? 'PUT' : 'POST',
      body: JSON.stringify(itemModal),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(item => {
        if(event.nativeEvent.submitter.name === 'validate'){
          handleValidateModalShow(item);
        }else{
          handleEditModalClose();
          toast.success(itemModal.id ? 'Producto modificado exitosamente' : 'Producto agregado exitosamente');
          return getProducts()
        }
      })
      .catch(function (err) {
        toast.error(err.message || 'Ha ocurrido un error al guardar el producto');
      });
  };

  const handleDelete = () => {
    fetcher(`${process.env.REACT_APP_API_URL}/product/${itemModal.id}`, {
      method: 'DELETE'
    })
      .then(_ => getProducts())
      .then(_ => {
        handleDeleteModalClose();
        toast.success('Producto eliminado exitosamente');
      })
      .catch(function (err) {
        toast.error(err.message || 'Ha ocurrido un error al eliminar el producto');
      });
  }

  const handlePricesModalShow = (itm) => {
    setPricesModalShow(true);
    setItemModal(itm);
  }

  const updatePrice = (id) => {
    const pricesFetch = fetcher(`${process.env.REACT_APP_API_URL}/product/${id}/price`, { method: 'POST' }, false)
      .then(_ => getProducts(false))

    toast.promise(pricesFetch, {
      loading: 'Actualizando precios...',
      success: 'Precios actualizados correctamente',
      error: 'Ha ocurrido un error al actualizar los precios',
    });
  }

  const handlePricesModalClose = () => {
    setPricesModalShow(false);
    setItemModal();
  }

  const formatDate = (date) => {
    let dateObject = new Date(date);
    return dateObject.toLocaleString();
  }

  const formatPrice = (productPrices, idMarket) => {
    let price = productPrices.find(p => p.idMarket === idMarket)
    return price && (showHistoryPrices || (!showHistoryPrices && price.isUpdated)) ? price.price : null;
  }

  const renderPrice = (productPrices, idMarket) => {
    let price = productPrices.find(p => p.idMarket === idMarket)
    return price && (showHistoryPrices || (!showHistoryPrices && price.isUpdated)) ? <Tooltip title={formatDate(price.date)} arrow>
      <span style={{ color: price.isUpdated ? "black" : "red" }}>{`$${price.price}`}</span>
    </Tooltip> : ''
  }

  const columns = [
    { field: "product", headerName: "Producto", width: 150 },
    { field: "brand", headerName: "Marca", width: 150 },
    { field: "variety", headerName: "Variedad", width: 150 },
    { field: "size", headerName: "Cont. neto", width: 80, valueFormatter: (value, row) => value + " " + row.sizeUnit },
    ...markets.map((market) => {
      return {
        field: market.name,
        headerName: market.name.charAt(0).toUpperCase() + market.name.slice(1),
        type: 'number',
        width: 110,
        headerClassName: "columnHeader-" + market.name,
        headerAlign: 'left',
        renderCell: (params) => renderPrice(params.row.prices, market.id),
        valueGetter: (value, row) => formatPrice(row.prices, market.id),
      }
    }),
    {
      field: "action",
      type: "actions",
      resizable: false,
      width: user.isAdmin ? 160 : 80,
      renderCell: (params) => {
        return <>
          <IconButton onClick={() => handlePricesModalShow(params.row)}><Tooltip title="Histórico de precios"><Search /></Tooltip></IconButton>
          {user.isAdmin ?
            <>
              <IconButton onClick={() => handleEditModalShow(params.row)}><Tooltip title="Editar"><Edit /></Tooltip></IconButton>
              <IconButton onClick={() => handleValidateModalShow(params.row)}><Tooltip title="Validar"><Done /></Tooltip></IconButton>
              <IconButton onClick={() => handleDeleteModalShow(params.row)}><Tooltip title="Eliminar"><Delete /></Tooltip></IconButton>
            </>
            :
            <IconButton onClick={() => updatePrice(params.row.id)}><Tooltip title="Actualizar precios"><Sync /></Tooltip></IconButton>
          }
        </>
      }
    }
  ];

  const rows = products;

  const headerClasses = {
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: 'inherit'
    }
  }
  markets.forEach(m => {
    let keyName = `& .columnHeader-${m.name}`;
    headerClasses[keyName] = {
      backgroundColor: m.backgroundColor,
      color: m.color,
      fontWeight: 'bolder',
      fontSize: 'large',
      boxShadow: 'inset 0 0 40px rgba(0, 0, 0, 0.15)'
    }
  });

  return (
    <Box>
      <Box sx={{ textAlign: "right" }}>
        {user.isAdmin ? <Button variant="contained" size="small" onClick={() => handleEditModalShow()} sx={{ marginBottom: 2, marginLeft: 2 }}>Agregar producto</Button> : ''}
      </Box>

      <Box sx={{ height: `calc(100dvh - 64px - ${user.isAdmin ? '46px' : '0px'} - 40px)` }}>
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{ toolbar: { showHistoryPrices, setShowHistoryPrices } }}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10000, page: 0 },
            },
          }}
          pageSizeOptions={[10000]}
          sx={{
            ...headerClasses
          }}
        />
      </Box>

      {itemModal ?
        <>
          <Dialog
            open={editModalShow}
            onClose={handleEditModalClose}
            title="Edición de producto"
            handleFormSubmit={handleEditModalSubmit}
            content={
              <>
                <TextField required fullWidth margin="dense" label="Producto" type="text" value={itemModal.product} onChange={(e) => setItemModal({ ...itemModal, product: e.target.value })} />
                <TextField required fullWidth margin="dense" label="Marca" type="text" value={itemModal.brand} onChange={(e) => setItemModal({ ...itemModal, brand: e.target.value })} />
                <TextField required fullWidth margin="dense" label="Variedad" type="text" value={itemModal.variety} onChange={(e) => setItemModal({ ...itemModal, variety: e.target.value })} />
                <TextField required fullWidth margin="dense" label="Cont. neto" type="text" value={itemModal.size} onChange={(e) => setItemModal({ ...itemModal, size: e.target.value })} />
                <FormControl fullWidth margin="dense" required>
                  <InputLabel>Unidades</InputLabel>
                  <Select label="Unidades" value={itemModal.sizeUnit} onChange={(e) => setItemModal({ ...itemModal, sizeUnit: e.target.value })} >
                    <MenuItem value="gr.">gr.</MenuItem>
                    <MenuItem value="lt.">lt.</MenuItem>
                    <MenuItem value="un.">un.</MenuItem>
                  </Select>
                </FormControl>
                <TextField fullWidth margin="dense" label="EAN" type="number" value={itemModal.ean} onChange={(e) => setItemModal({ ...itemModal, ean: e.target.value })} />
                {
                  itemModal.codes ?
                    markets.map(m => <TextField key={m.id} fullWidth margin="dense" label={"Codigo " + m.name} type="text" value={itemModal.codes[m.id] || ''} onChange={(e) => { let changes = {}; changes[m.id] = e.target.value; setItemModal({ ...itemModal, codes: { ...itemModal.codes, ...changes } }) }} />)
                    : ''
                }
              </>}
            actions={
              <>
                <Button variant="outlined" color="primary" type="submit" name="validate">Guardar y validar</Button>
                <Button variant="contained" color="primary" type="submit" name="save">Guardar</Button>
              </>
            }
          />
          <Dialog
            open={deleteModalShow}
            onClose={handleDeleteModalClose}
            title="¡Atención!" content={<>¿Está seguro que desea eliminar el producto <b>{itemModal.product} {itemModal.brand} {itemModal.variety} {itemModal.size}</b>?<br />Se eliminarán todos los datos del mismo</>}
            actions={<>
              <Button variant="outlined" color="primary" onClick={handleDeleteModalClose}>Cancelar</Button>
              <Button variant="contained" color="primary" onClick={handleDelete}>Eliminar</Button>
            </>}
          />
          <Dialog
            open={pricesModalShow}
            onClose={handlePricesModalClose}
            fullScreen={fullScreen}
            title={<React.Fragment>Histórico de precios - <b>{itemModal.product} {itemModal.brand} {itemModal.variety} {itemModal.size}</b></React.Fragment>}
            maxWidth='xl'
            content={
              <History fetcher={fetcher} item={itemModal} markets={markets} fullScreen={fullScreen} />
            }
          />
          <Dialog
            open={validateModalShow}
            onClose={handleValidateModalClose}
            title={<React.Fragment>Validación - <b>{itemModal.product} {itemModal.brand} {itemModal.variety} {itemModal.size}</b></React.Fragment>}
            content={
              <Validation fetcher={fetcher} item={itemModal} markets={markets} />
            }
          />
        </>
        : ''
      }
    </Box>
  );
}

export default Products;
