import { MenuItem } from '@mui/material';
import * as XLSX from 'xlsx';
import { config } from './config';
import React from 'react';
import {
  gridFilteredSortedRowIdsSelector,
  gridColumnDefinitionsSelector,
  useGridApiContext,
} from '@mui/x-data-grid';

function getExcelData(apiRef) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumns = gridColumnDefinitionsSelector(apiRef).filter(c => c.computedWidth > 0 && c.field != 'action').map(c => { return { field: c.field, displayName: c.headerName}})

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumns.forEach(c => {
      row[c.field] = apiRef.current.getCellParams(id, c.field).formattedValue;
    });
    return row;
  });

  return { columns: visibleColumns, data };
}

function handleExport(apiRef) {
  const { columns, data } = getExcelData(apiRef);

  const rows = data;

  const worksheet = XLSX.utils.json_to_sheet(rows);
  XLSX.utils.sheet_add_aoa(worksheet, [columns.map(c => c.displayName)], {
    origin: 'A1',
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, config.sheetName);
  XLSX.writeFile(workbook, config.fileName, { compression: true });
}

export function ExportMenuItem(props) {
  const apiRef = useGridApiContext();
  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        handleExport(apiRef);
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      Excel
    </MenuItem>
  );
}
