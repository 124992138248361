import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs(props) {

  return (
    <BootstrapDialog
      onClose={props.onClose}
      open={props.open}
      fullWidth={true}
      maxWidth={props.maxWidth || 'sm'}
      fullScreen={props.fullScreen || false}
      PaperProps={{
        component: 'form',
        onSubmit: props.handleFormSubmit,
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {props.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
        <DialogContent dividers>
          {props.content}
        </DialogContent>
        <DialogActions>
          {props.actions}
        </DialogActions>
    </BootstrapDialog>
  );
}