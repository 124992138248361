import React, { useState } from 'react';
import { Paper, Button, TextField, InputAdornment, Container, Box, Grid, Typography } from '@mui/material';
import { AccountCircle, VpnKey } from '@mui/icons-material';
import toast from 'react-hot-toast';

function Login(props) {
  const { fetcher, setSession } = props;
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');

  let handleClick = () => {
    if (username !== '' && password !== '') {
      fetcher(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      })
        .then(function (user) {
          setSession(user);
        })
        .catch(function (error) {
          toast.error(error.message);
          setPassword('');
        });
    } else {
      toast.error('El usuario o la clave son incorrectos');
      setPassword('');
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  };

  return (
    <Box component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100dvh',
        overflow: 'auto',
        display: "flex",
      }}>
      <Container maxWidth="xs" className="login" sx={{
        flexDirection: "column",
        justifyContent: "center",
        display: "flex",
      }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Paper elevation={10} sx={{ padding: 4, textAlign: "center"}} >
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h4" sx={{color: 'primary.main', fontWeight: 'bold'}}>PreciosOnline</Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Usuario"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  label="Clave"
                  type="password"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKey />
                      </InputAdornment>
                    )
                  }}
                />
                <Button variant="contained" fullWidth color="primary" type="submit" style={{ marginTop: "10px", marginBottom: "0" }}>Iniciar sesión</Button>
              </form>
            </Paper >
          </Grid>
        </Grid>
      </Container >
    </Box>
  );
}

export default Login;