import React, { useState, useEffect } from "react";
import { Box, Button } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { esES } from '@mui/x-data-grid/locales';
import toast from 'react-hot-toast';

function UserProducts(props) {
  const { fetcher, user, handleClose } = props;
  const [products, setProducts] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/user/${user.id}/products`)
      .then(res => setProducts(res))
      .catch(_ => toast.error('Ha ocurrido un error al obtener los productos'));
  }, []);

  useEffect(() => {
    setRowSelectionModel(products.filter(itm => itm.assigned).map(itm => itm.id));
  }, [products]);

  const columns = [
    { field: "product", headerName: "Producto", width: 150 },
    { field: "brand", headerName: "Marca", width: 150 },
    { field: "variety", headerName: "Variedad", width: 150 },
    { field: "size", headerName: "Cont. neto", width: 80, valueFormatter: (value, row) => value + " " + row.sizeUnit },
    { field: "ean", headerName: "EAN", width: 150 }
  ];

  const rows = products;

  const handleSaveProductsClick = () => {
    fetcher(`${process.env.REACT_APP_API_URL}/user/${user.id}/products`, {
      method: 'PUT',
      body: JSON.stringify(rowSelectionModel),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(_ => {
        handleClose();
        toast.success('Productos asignados exitosamente');
      })
      .catch(function (err) {
        toast.error(err.message || 'Ha ocurrido un error al guardar los productos');
      });
  }

  return (
    <Box>
      <DataGrid sx={{ height: "calc(100dvh - 64px - 46px - 100px)" }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10000, page: 0 },
          },
        }}
        pageSizeOptions={[10000]}
      />
      <Box sx={{ textAlign: "right" }}>
        <Button variant="contained" size="small" onClick={() => handleSaveProductsClick()} sx={{ marginTop: 2, marginLeft: 2 }}>Guardar productos</Button>
      </Box>
    </Box>
  );
}

export default UserProducts;
