import React, { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, CardMedia, TextField, Typography } from '@mui/material';
import toast from 'react-hot-toast';

function SearchProducts(props) {
  const { fetcher } = props;
  const [markets, setMarkets] = useState([]);
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/market`)
      .then(res => setMarkets(res))
      .catch(_ => toast.error('Ha ocurrido un error al obtener los supermercados'));
  }, []);

  const getProducts = (query) =>
    fetcher(`${process.env.REACT_APP_API_URL}/market/products?text=${query}`)
      .then(res => setProducts(res))
      .catch(_ => toast.error('Ha ocurrido un error al obtener los productos'));

  const mapMarketName = (marketId) => {
    let market = markets.find(m => m.id === marketId)
    return market.name.charAt(0).toUpperCase() + market.name.slice(1)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', marginTop: 2 }}>
        <TextField label="Nombre del producto" variant="outlined" size="small" sx={{ flex: 'auto' }} value={query} onChange={(e) => setQuery(e.target.value)} /><Button variant="contained" onClick={() => getProducts(query)} sx={{ marginBottom: 2, marginLeft: 2 }}>Buscar productos</Button>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {products.map(itm => <Box key={itm.marketId}>
          <h3>{mapMarketName(itm.marketId)}</h3>
          <Box sx={{ marginTop: 2, display: 'flex', overflowX: 'auto' }}>
            {itm.products.map(p =>
              <Box key={p.title} sx={{ flex: "0 0 500px", margin: 2 }}>
                <Card sx={{ display: "flex" }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: 'auto' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        {p.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        ${p.price}
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                        <a href={p.link} target='_blank'><Button variant="outlined">Link</Button></a>
                      </Box>
                    </CardContent>
                  </Box>
                  {
                    p.image ?
                      <CardMedia
                        component="img"
                        sx={{ width: 151, height: 181 }}
                        image={p.image}
                      /> : ''
                  }
                </Card>
              </Box>)}
          </Box>
        </Box>
        )}
      </Box>
    </Box>
  );
}

export default SearchProducts;
