import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Divider, IconButton, ListItem, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { AccountCircle, Person, Menu as MenuIcon, Logout, Key } from '@mui/icons-material';
import ChangePassword from "./ChangePassword";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function NavBar(props) {
  const { removeSession, fetcher, user, open, toggleDrawer } = props;

  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  const openAccountMenu = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const closeAccountMenu = () => {
    setAccountAnchorEl();
  };

  const openPasswordChangeModal = () => {
    closeAccountMenu();
    setShowPasswordChangeModal(true);
  }

  return <React.Fragment>
    <AppBar position="absolute" open={open}>
      <Toolbar sx={{
        pr: '24px'
      }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Typography sx={{ flexGrow: 1 }} variant="h6">
          PreciosOnline
        </Typography>

        <IconButton onClick={openAccountMenu} style={{ color: "white" }}><AccountCircle /></IconButton>
      </Toolbar>
      <Menu
        anchorEl={accountAnchorEl}
        keepMounted
        open={Boolean(accountAnchorEl)}
        onClose={closeAccountMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ListItem sx={{ marginBottom: 1 }}>
          <ListItemIcon style={{ "minWidth": "36px" }}>
            <Person fontSize="small" />
          </ListItemIcon>
          {user.username}
        </ListItem>
        <Divider />
        <MenuItem onClick={openPasswordChangeModal}>
          <ListItemIcon>
            <Key fontSize="small" />
          </ListItemIcon>
          Cambiar contraseña
        </MenuItem>
        <MenuItem onClick={() => { removeSession(); setAccountAnchorEl(); }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </AppBar>
    {showPasswordChangeModal ? <ChangePassword fetcher={fetcher} handleClose={() => setShowPasswordChangeModal(false)} /> : ''}
  </React.Fragment>
}

export default NavBar;